<template>
  <div class="m-2">
    <evaluation v-if="data && data.appServiceType === 'E_BAHOLASH'" :data="data"/>
    <e-xarid v-if="data && data.appServiceType === 'E_XARID'" :data="data"/>
  </div>
</template>

<script>
import Evaluation from "@/components/qr-code/evaluation";
import EXarid from "@/components/qr-code/e-xarid";
export default {
  name: "sign",
  components: {
    EXarid,
    Evaluation
  },
  data() {
    return {
      data: {
        appServiceType : ''
      }
    }
  },
  methods: {
    init() {
      this.$http.get('/qr-code/url-param-decode/' + this.$route.params.code).then(res => {
        this.data = res.data
      }, err => {
        console.log(err)
      })
    }
  },
  created() {
    this.init()
  }
}
</script>

<style>
#app{
  text-align: justify;
}
</style>